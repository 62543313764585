import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import banner22 from "../images/banner Images/banner22.jpg";
import banner48 from "../images/banner Images/banner48.jpg";
import banner23 from "../images/banner Images/banner23.jpg";
import banner21 from "../images/banner Images/banner21.jpg";
import banner64 from "../images/banner Images/banner64.jpg";
import banner65 from "../images/banner Images/banner65.jpg";
import CompanyDetails from "../components/CompanyDetails";
import Images from "../components/Image";
import "../App.css";
import Experience from "../components/Experience";
import Choose from "../components/Choose";
const About = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      w="100%"
      bgColor="#07274a"
      color="white"
    >
      <Images />
      <Text
        fontSize={{ base: "20px", md: "40px" }}
        color="white"
        marginTop="20px"
        fontWeight="bold"
        as="span"
        className="scrolling-text"
        textAlign="center"
      >
        Welcome to Kesava Security Systems
      </Text>
      <Box>
        <Text
          fontSize={{ base: "16px", md: "18px" }}
          marginTop="10px"
          marginLeft={{ base: "10px", md: "20px" }}
          fontWeight="bold"
        >
          Kesava Security Systems are the best Security Systems. We are proud to
          offer a wide selection of high-quality CCTV cameras that are both
          reliable and affordable. Here's why you should choose our CCTV
          cameras.
        </Text>
      </Box>

      <Box display="flex" justifyContent="center">
        <Text
          fontSize={{ base: "24px", md: "40px" }}
          color="white"
          marginTop="20px"
          fontWeight="bold"
          marginLeft="20px"
        >
          Services
        </Text>
      </Box>
      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-evenly"
        paddingTop={{ base: "15px", md: "30px" }}
        paddingBottom={{ base: "15px", md: "30px" }}
        w="100%"
      >
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner22}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>

          <Box display="flex" justifyContent="center" alignContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              Installation
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="100%"
              textAlign="start"
            >
              Our Company provides a smart assemblage of CCTV on rent. Our CCTV
              cameras are hi-tech, embedded with modern features and with better
              quality assurance.
            </Text>
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner65}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              For Rent
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="90%"
              textAlign="start"
            >
              Our Company provides a smart assemblage of CCTV on rent. Our CCTV
              cameras are hi-tech, embedded with modern features and with better
              quality assurance.
            </Text>
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner48}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              Selling
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="90%"
              textAlign="start"
            >
              Our Company is poised to be among the country’s leading CCTV
              equipment. Our equipment consists of quality CCTV, alarm, and
              access control systems.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-evenly"
        paddingTop={{ base: "15px", md: "30px" }}
        paddingBottom={{ base: "15px", md: "30px" }}
      >
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner23}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              Service
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="90%"
              textAlign="start"
            >
              CCTV maintenance is a crucial step in ensuring your surveillance
              system is always up and running. Proactive servicing prevents
              equipment failure.
            </Text>
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner21}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              Replacements
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="90%"
              textAlign="start"
            >
              CCTV Replacement is a crucial step in the CCTV services. Our
              company gives replacements according to the products and with
              better quality.
            </Text>
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "400px" }}
          height="600px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image
              src={banner64}
              height="300px"
              width="100%"
              borderRadius="20px"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Text fontSize={{ base: "24px", md: "30px" }} fontWeight="bold">
              Installments
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            textAlign="center"
            paddingTop="10px"
            paddingLeft="15px"
            paddingRight="15px"
          >
            <Text
              fontSize={{ base: "16px", md: "20px" }}
              w="90%"
              textAlign="start"
            >
              The CCTV will be provided in the installment Schemes. According to
              company rules, the products will be provided in EMI Installments.
            </Text>
          </Box>
        </Box>
      </Box>

      <CompanyDetails />
      <Experience />
      <Choose />
    </Box>
  );
};

export default About;
