import { Box } from "@chakra-ui/react";
import React from "react";

import Address from "../components/Address";

const Contact = () => {
  return (
    <Box
      display="flex"
      flexDirection="column" // Change to column for mobile view
      w="100%"
      bgColor="#07274a"
      color="white"
    >
      <Address />
    </Box>
  );
};

export default Contact;
