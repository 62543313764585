import { Box } from "@chakra-ui/react";
import React from "react";
import nodata from "../images/Nodata.png";

const NoData = () => {
  return (
    <Box
      w="100%"
      h="100vh"
      bgColor="#07274a"
      bgImage={`url(${nodata})`}
      backgroundSize="cover"
      backgroundPosition="center"
    />
  );
};

export default NoData;
