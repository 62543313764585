import img1 from "./images/vga-hdmi-extender.webp";
import img2 from "./images/updatedImages/hikvison2.jpg";
import img3 from "./images/cppluscam.jpeg";
import img4 from "./images/dvr.jpeg";
import img5 from "./images/cpplus-dvr-camera.jpeg";
import img6 from "./images/cctv-outdoor-camera-housings.jpeg";
import img7 from "./images/cctv-camera-mic.jpeg";
import img8 from "./images/cctv-camera-lens.jpeg";
import img9 from "./images/cctv-cable.jpeg";
import img10 from "./images/bnc-cable.jpeg";
import img11 from "./images/dahua-camera.jpeg";
import img12 from "./images/banner Images/banner24.jpg";
import img13 from "./images/updatedImages/IMG-20240215-WA0055.jpg";
import img14 from "./images/updatedImages/hikvison1.jpg";
import img15 from "./images/updatedImages/IMG-20240215-WA0099.jpg";
import img16 from "./images/updatedImages/IMG-20240215-WA0100.jpg";

const products = [
  {
    id: 1,
    image: img11,
    name: "Dahua Camera",
    BusinessType: "Manufacturer, Supplier, Retailer, Wholesaler",
    Type: "Dahua Camera",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 2,
    image: img12,
    name: "Hikvision Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 3,
    image: img2,
    name: "Hikvision Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },

  {
    id: 4,
    image: img3,
    name: "CP Plus Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    BrandName: "CP Plus",
    Application: "Home, Hotel",
    Feature: "Easy Function, Easy To Install",
    Condition: "New",
    Driven: "Electric",
    Warranty: "1 year",
    Installations: "Easy to Install",
  },
  {
    id: 5,
    image: img14,
    name: "Hikvision Audio Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 6,
    image: img13,
    name: "Hikvision Audio Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 7,
    image: img15,
    name: "Hikvision Analog HD Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 8,
    image: img16,
    name: "Hikvision Analog HD Camera",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Application: "Home, Office, Security",
    Feature: "Durable, Easy To Install",
    Driven: "Type	Electric",
    Warranty: "1 year",
    vision: "Day Vision, Night Vision",
    Installations: "Easy to Install",
  },
  {
    id: 9,
    image: img8,
    name: "CCTV Camera Lens",
    BusinessType: "Manufacturer, Supplier, Retailer, Wholesaler",
    Focallength: "2.7-12mm 3.5-14mm 8-24mm 5-50mm",
    Application: "1/3 format cameras",
    LensFormat: "1/3",
    Installations: "Easy to Install",
    Driven: "Type	Electric",
  },
  {
    id: 10,
    image: img4,
    name: "Dahua DVR",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Type: "Dvr Camera",
    Style: "Antique, Stylish",
    Feature: "Durable, Easy To Use",
    Condition: "Good,New",
    PackagingType: "Box",
    Certificate: "CE Certified",
    Installations: "Easy to Install",
    Application: "Cable Modems",
    Driven: "Type	Electric",
  },
  {
    id: 11,
    image: img5,
    name: "CP Plus DVR",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Type: "Dvr Camera",
    Style: "Stylish",
    Feature: "Durable, Easy To Use",
    Condition: "Good",
    PackagingType: "Box",
    Certificate: "CE Certified",
    CountryofOrigin: "India",
    Installations: "Easy to Install",
    Driven: "Type	Electric",
    Application: "Cable Modems",
  },
  {
    id: 12,
    image: img1,
    name: "VGA HDMI Extender",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    HDMISwicher: "5 input and 1 output",
    Usage: "Projector, LCD TV, set up box, Game etc",
    Installations: "Easy to Install",
    Application: "Cable Modems",
    Driven: "Type	Electric",
  },
  {
    id: 13,
    image: img6,
    name: "CCTV Outdoor Camera Housings",
    BusinessType: "	Manufacturer, Supplier, Retailer, Wholesaler",
    Color: "White, Black",
    Material: "Metal",
    Pack: "Box",
    Installations: "Easy to Install",
    Application: "Home, Hotel",
    Driven: "Type	Electric",
  },
  {
    id: 14,
    image: img7,
    name: "CCTV Camera Mic",
    BusinessType: " Manufacturer,Supplier Retailer Wholesaler",
    Color: "White, black",
    Communication: "Wired",
    DeliveryTime: "24 Hours",
    Installations: "Easy to Install",
    Application: "Cable Modems",
    Driven: "Type	Electric",
  },

  {
    id: 15,
    image: img9,
    name: "CCTV Cable",
    BusinessType: "Manufacturer, Supplier, Retailer, Wholesaler",
    Length: "90m",
    CameraStyle: "Bullet Camera",
    CableDiameter: "10 mm",
    Installations: "Easy to Install",
    Application: "Cable Modems",
    Driven: "Type	Electric",
  },
  {
    id: 16,
    image: img10,
    name: "BNC Cable",
    BusinessType: "Manufacturer, Supplier, Retailer, Wholesaler",
    Material: "Metal",
    Application: "Cable Modems",
    Connector: "Orientation	Straight",
    Installations: "Easy to Install",
    Driven: "Type	Electric",
  },
];
export default products;
