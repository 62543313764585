import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import footer from "../images/footer.jpg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <Box
      w="100%"
      bgColor="#2c6483"
      display="flex"
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-evenly"
      p={{ base: 5, md: 10 }}
      backgroundImage={`url(${footer})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <Box display="flex" flexDirection="column" color="white">
        <Text color="white" fontSize="24px" fontWeight="bold" w="180px" mb={5}>
          Our Company
        </Text>

        <Link to="/" color="white" fontSize="18px" mb={2}>
          Home
        </Link>
        <Link to="/about" color="white" fontSize="18px" mb={2}>
          About
        </Link>
        <Link to="/products" color="white" fontSize="18px" mb={2}>
          Products
        </Link>
        <Link to="/gallery" color="white" fontSize="18px" mb={2}>
          Gallery
        </Link>
        <Link to="/contact" color="white" fontSize="18px" mb={2}>
          Contact Us
        </Link>
        <Box display="flex" flexDirection="column">
          <Text
            color="white"
            fontSize="24px"
            fontWeight="bold"
            w="180px"
            mb={5}
          >
            Services
          </Text>

          <Text color="white" fontSize="18px">
            For Sale
          </Text>
          <Text color="white" fontSize="18px">
            To Installments
          </Text>
          <Text color="white" fontSize="18px">
            For Rent
          </Text>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column">
        <Text color="white" fontSize="24px" fontWeight="bold" w="180px" mb={5}>
          Our Branches
        </Text>
        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          textDecoration="underline"
        >
          Hyderabad
        </Text>
        <Text color="white" fontSize="18px" mb={2} w="300px">
          <a
            target="_blank"
            href="https://www.google.com/maps/search/6%2F3%2F630%2F5+Anand+Nagar+Colony,+Khairtabad+Hyderabad,+Telangana+500004/@17.4113802,78.4565502,17z/data=!3m1!4b1?entry=ttu"
          >
            H-No-6-3-630/5, Anand Nagar, Kairathabad Hyderabad, Telangana 500004
          </a>
        </Text>
        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          textDecoration="underline"
        >
          Guntur
        </Text>
        <Text color="white" fontSize="18" mb={2} w="300px">
          Near NTR Park, Railway Station Road,Sattenapalli, Guntur, Andhra
          Pradesh
        </Text>
        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          textDecoration="underline"
        >
          Vijayawada
        </Text>

        <Text
          color="white"
          fontSize="18px"
          fontWeight="bold"
          textDecoration="underline"
        >
          Khammam
        </Text>
      </Box>

      <Box display="flex" flexDirection="column">
        <Text color="white" fontSize="24px" fontWeight="bold" w="180px" mb={5}>
          Contact Us
        </Text>

        <Box display="flex" flexDirection="row">
          <Box paddingLeft="5px" paddingTop="11px">
            <FaWhatsapp height="200px" width="100px" color="white" />
          </Box>

          <Box paddingLeft="15px" paddingTop="3px">
            <Text color="white" fontSize="18">
              <a href="tel:+918886126474">8886126474</a>
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box paddingLeft="5px" paddingTop="15px">
            <FaPhoneAlt height="200px" width="100px" color="white" />
          </Box>
          <Box paddingLeft="15px" paddingTop="3px" mt={1}>
            <Text color="white" fontSize="18">
              <a href="tel:+918639296905">86392 96905</a>
            </Text>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box paddingLeft="5px" paddingTop="15px">
            <AiTwotoneMail height="200px" width="100px" color="white" />
          </Box>
          <Box paddingLeft="15px" paddingTop="3px" mt={1}>
            <Text color="white" fontSize="18">
              <a href="mailto:contact@kesavasecuritysystems.com">
                contact@kesavasecuritysystems.com
              </a>
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box paddingLeft="5px" paddingTop="15px">
            <FaFacebookSquare height="200px" width="100px" color=" white" />
          </Box>
          <Box paddingLeft="15px" paddingTop="3px" mt={1}>
            <Text color="white" fontSize="18">
              <a
                href="https://www.facebook.com/profile.php?id=61554789399216/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kesava Security Systems
              </a>
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box paddingLeft="5px" paddingTop="15px">
            <FaSquareInstagram height="200px" width="100px" color="white" />
          </Box>
          <Box paddingLeft="15px" paddingTop="3px" mt={1}>
            <Text color="white" fontSize="18">
              <a
                style={{ marginTop: "3" }}
                href="https://www.instagram.com/kesavasecuritysystems/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kesava Security Systems
              </a>
            </Text>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ md: "flex-start" }}
          mt={{ lg: 5, base: 5, md: 0 }}
        >
          <Box mb={{ base: 3, md: 0 }} mt={3}>
            <IoLocationOutline height="200px" width="100px" color="white" />
          </Box>
          <Box paddingLeft={{ base: 0, md: 15 }} paddingTop="3px">
            <Text color="white" fontSize="18">
              Near NTR Park, Railway Station Road,
            </Text>
            <Text color="white" fontSize="18">
              Sattenapalli, Guntur, Andhra Pradesh
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
