import React, { useState, useEffect } from "react";
import { Text, Box } from "@chakra-ui/react";

const AnimatedText = () => {
  const text =
    "Keesava Security Systems is a reputed Manufacturer and Supplier of VGA HDMI Extender, BNC Pin, CCTV Cable, CCTV Camera Lens, CCTV Camera Mic, CCTV Outdoor Camera Housings, CCTV Power Supply etc. We consider the prevalent market requirements and offer products accordingly. We design products to meet the varied clients needs. For us, nothing matters more than product quality. We serve clients in the best ways by offering flawless equipment. Our products are in high demand across domestic markets. Also, we offer all our products at the best rates. We have established a wide base of clients throughout the country since our company's incorporation.";

  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let index = 0;
    setDisplayedText("");
    const intervalId = setInterval(() => {
      setDisplayedText((prevText) => prevText + text[index]);
      index++;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box>
      <Text fontSize={{ base: "14px", md: "20px" }} whiteSpace="pre-line">
        {displayedText}
      </Text>
    </Box>
  );
};

export default AnimatedText;
