import React from "react";
import products from "../data";
import { Box, Img, Text, chakra, SimpleGrid, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import backgroundImg from "../images/product-background.jpg";

const HoverableText = chakra(Text, {
  baseStyle: {
    transition: "color 0.3s",
    _hover: { color: "red" },
  },
});

const Products = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgColor="#07274a"
    >
      <Box
        backgroundImage={backgroundImg}
        h={100}
        w="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={{ lg: "10%", base: "20%", md: "10%" }}
      >
        <Text color="white" fontWeight="bold" fontSize="30">
          Products
        </Text>
        <Text color="white" fontWeight="bold" fontSize="30"></Text>
      </Box>
      <Center w={{ base: "100%", md: "100%" }}>
        <Box>
          <SimpleGrid
            columns={{ base: 1, md: 4 }}
            spacing={{ base: 4, md: 6 }}
            p={{ base: 2, md: 4 }}
          >
            {products.map((product) => (
              <Link key={product.id} to={`/products/${product.name}`}>
                <Box
                  bgColor="white"
                  p={{ base: 2, md: 4 }}
                  borderRadius={5}
                  fontFamily="cursive"
                >
                  <Img
                    mt={4}
                    src={product.image}
                    alt={product.name}
                    width="100%"
                    height="200px"
                    objectFit="contain"
                    borderWidth={1}
                    borderColor="gray"
                  />
                  <HoverableText
                    fontWeight="bold"
                    mt={5}
                    mb={5}
                    textAlign={{ base: "center" }}
                  >
                    {product.name}
                  </HoverableText>
                </Box>
              </Link>
            ))}
          </SimpleGrid>
        </Box>
      </Center>
    </Box>
  );
};

export default Products;
