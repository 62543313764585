import { Box, Text, Image, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import banner34 from "../images/banner Images/banner34.png";
import banner37 from "../images/banner Images/banner37.png";
import banner35 from "../images/banner Images/banner40.png";

const Experience = () => {
  const [count, setCount] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [experience, setExperience] = useState(0);

  useEffect(() => {
    const targetCount = 560;
    let currentCount = 0;

    const customersCount = 540;
    let currentCustomerCount = 0;

    const experienceCount = 8;
    let currentExperienceCount = 0;

    const interval = setInterval(() => {
      if (currentCount < targetCount) {
        setCount(currentCount);
        currentCount += 5;
      } else {
        setCount(targetCount);
        clearInterval(interval);
      }
    }, 50);

    const customer = setInterval(() => {
      if (currentCustomerCount < customersCount) {
        setCustomers(currentCustomerCount);
        currentCustomerCount += 5;
      } else {
        setCustomers(customersCount);
        clearInterval(customer);
      }
    }, 50);

    const experienceInterval = setInterval(() => {
      if (currentExperienceCount < experienceCount) {
        setExperience(currentExperienceCount);
        currentExperienceCount += 1;
      } else {
        setExperience(experienceCount);
        clearInterval(experienceInterval);
      }
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(customer);
      clearInterval(experienceInterval);
    };
  }, []);

  return (
    <Box
      width="100%"
      height={{ base: "auto", md: "400px" }}
      // bgColor="#0a396c"
      marginTop={{ base: "40px", md: "80px" }}
      paddingTop={{ base: "20px", md: "55px" }}
      marginBottom={{ base: "40px", md: "80px" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      bgColor="#07274a"
    >
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 5, md: 10 }}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Image
            src={banner37}
            height="130px"
            width="130px"
            objectFit="cover"
          />
          <Text
            fontSize={{ base: "30px", md: "40px" }}
            color="white"
            marginTop="3"
          >
            {experience}+
          </Text>
          <Text fontSize={{ base: "24px", md: "40px" }} color="white">
            Years Experience
          </Text>
        </Box>
        <Box
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Image
            src={banner34}
            height="130px"
            width="130px"
            objectFit="cover"
          />
          <Text
            fontSize={{ base: "30px", md: "40px" }}
            color="white"
            marginTop="3"
          >
            {count}+
          </Text>
          <Text fontSize={{ base: "24px", md: "40px" }} color="white">
            Projects Completed
          </Text>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          h="90%"
        >
          <Image src={banner35} w="40%" objectFit="cover" />
          <Text fontSize={{ base: "30px", md: "40px" }} color="white">
            {customers}+
          </Text>
          <Text fontSize={{ base: "24px", md: "40px" }} color="white">
            Happy Customers
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Experience;
