import { Box, Text, Img } from "@chakra-ui/react";
import React from "react";
import banner38 from "../images/BrandImages/cpplus.jpg";
import banner39 from "../images/BrandImages/dahua.jpg";
import banner40 from "../images/BrandImages/hikvision.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css"; // Import the main swiper styles
import SwiperCore, { Autoplay } from "swiper/core";
import "../App.css";

SwiperCore.use([Autoplay]);

const OurBrands = () => {
  return (
    <Box bgColor="white">
      <Box display="flex" justifyContent="center">
        <Text
          fontSize={{ base: "24px", md: "40px" }}
          fontWeight="bold"
          color="black"
        >
          Our Brand Patners
        </Text>
      </Box>
      <Swiper
        className="sample-slider"
        modules={[Autoplay]}
        loop={true}
        autoplay={{ delay: 2000 }}
        slidesPerView={3}
        speed={10000}
        spaceBetween={5}
      >
        <SwiperSlide>
          <Img h={100} src={banner38} />
        </SwiperSlide>
        <SwiperSlide>
          <Img h={100} src={banner39} />
        </SwiperSlide>
        <SwiperSlide>
          <Img h={100} src={banner40} />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default OurBrands;
