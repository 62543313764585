import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import HomeSlider from "../components/HomeSlider";
import OurBrands from "../components/OurBrands";
import "../App.css";
import AnimatedText from "../components/AnimatedText";
import ProductCard1 from "../components/ProductCard1";

const HomePage = () => {
  return (
    <Box display="flex" flexDirection="column" w="100%" fontFamily="helvetica">
      <Box
        display="flex"
        flexDirection="column"
        w="100%"
        textAlign="center"
        alignItems="center"
      >
        <Box w="100%">
          <HomeSlider />
        </Box>
        <Box width={{ base: "100%", md: "100%" }} p={{ base: 4, md: 8 }}>
          <Text
            fontSize={{ base: "16px", md: "24px" }}
            fontWeight="bold"
            mt="30px"
            mb="30px"
          >
            WELCOME TO OUR WEBSITE
          </Text>
          <Center w={{ base: "100%", md: "100%" }}>
            <AnimatedText />
          </Center>
        </Box>

        <Box
          display="flex"
          w="100%"
          bgColor="#07274a"
          justifyContent="center"
          p={4}
        >
          <ProductCard1 />
        </Box>
      </Box>
      <OurBrands />
    </Box>
  );
};

export default HomePage;
