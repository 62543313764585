import { Box, Text, Image, SimpleGrid, Center } from "@chakra-ui/react";
import React from "react";
import banner38 from "../images/banner Images/banner38.png";
import banner39 from "../images/banner Images/banner39.png";
import banner40 from "../images/banner Images/banner40.png";

const Choose = () => {
  return (
    <Box w="100%" bgColor="#07274a">
      <Box
        display="flex"
        justifyContent="center"
        w="100%"
        mb={{ base: "4", md: "8" }}
      >
        <Text fontSize={{ base: "24px", md: "40px" }} fontWeight="bold">
          Why Choose Us
        </Text>
      </Box>

      <Center>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 5, md: 10 }}
          paddingTop={{ base: "10px", md: "30px" }}
          paddingBottom={{ base: "10px", md: "30px" }}
        >
          <Box
            width={{ lg: "400px", base: "300px", md: "300px" }}
            height="400px"
            borderWidth="4px"
            borderRadius="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            margin="auto"
            maxWidth={{ base: "300px", md: "400px" }}
          >
            <Image
              src={banner38}
              height={{ base: "120px", md: "250px" }}
              width={{ base: "120px", md: "250px" }}
            />
            <Text
              fontSize={{ base: "18px", md: "30px" }}
              fontWeight="bold"
              mt="4"
            >
              Experience
            </Text>
          </Box>

          <Box
            width={{ lg: "400px", base: "300px", md: "300px" }}
            height="400px"
            borderWidth="4px"
            borderRadius="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
            margin="auto"
          >
            <Image
              src={banner39}
              height={{ base: "120px", md: "250px" }}
              width={{ base: "120px", md: "250px" }}
              ml={{ lg: "5", base: "0", md: "-50px" }}
            />
            <Text
              fontSize={{ base: "18px", md: "30px" }}
              fontWeight="bold"
              mt="4"
            >
              Repair & Service
            </Text>
          </Box>
          <Box
            width={{ base: "300px", md: "400px" }}
            height="400px"
            borderWidth="4px"
            borderRadius="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <Image
              src={banner40}
              height={{ base: "120px", md: "250px" }}
              width={{ base: "120px", md: "250px" }}
            />
            <Text
              fontSize={{ base: "18px", md: "30px" }}
              fontWeight="bold"
              mt="4"
            >
              Projects
            </Text>
          </Box>
        </SimpleGrid>
      </Center>
    </Box>
  );
};

export default Choose;
