import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import backgroundImg from "../images/product-background.jpg";
import products from "../data";

const ProductEach = () => {
  const { name } = useParams();
  const product = products.find((p) => p.name === name);
  const bImg = product.image;

  return (
    <Box
      display="flex"
      flexDirection="column"
      w="100%"
      bgColor="#07274a"
      color="white"
    >
      <Box
        backgroundImage={backgroundImg}
        h={100}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={{ lg: "10%", base: "40%", md: "18%" }}
      >
        <Text color="white" fontWeight="bold" fontSize="30">
          {product.name}
        </Text>
      </Box>
      <Center>
        <Box
          display="flex"
          flexDirection={{ base: "column", lg: "row", md: "row" }}
          w={{ base: "100%", md: "80%" }}
          borderWidth={1}
          borderColor="gray"
          m={6}
          bgColor="white"
          borderRadius={15}
          color="black"
        >
          <Box w={{ lg: "70%", base: "100%", md: "40%" }} h={350} p={5}>
            <Box
              w="100%"
              h="100%"
              backgroundImage={bImg}
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundPosition="center"
            ></Box>
          </Box>
          <Box
            w={{ base: "100%", md: "60%" }}
            display="flex"
            flexDirection="column"
            borderLeftWidth={{ lg: 1, md: 0, base: 0 }}
            borderLeftColor="gray"
            padding={5}
          >
            <Box>
              <Text fontWeight="bold" fontSize="30" textAlign="center">
                {name}
              </Text>
            </Box>
            <VStack
              mt={25}
              spacing={4}
              align="stretch"
              maxH={{ md: "300px", lg: "400px" }}
              overflowY="auto"
            >
              <Box display="flex" flexDirection="row">
                <Text fontWeight="bold">Business Type: </Text>
                <Text ml={5}>{product.BusinessType}</Text>
              </Box>
              <Box display="flex" flexDirection="row">
                <Text fontWeight="bold">Driven: </Text>
                <Text ml={5}>{product.Driven}</Text>
              </Box>
              <Box display="flex" flexDirection="row">
                <Text fontWeight="bold">Application: </Text>
                <Text ml={5}>{product.Application}</Text>
              </Box>
              <Box display="flex" flexDirection="row">
                <Text fontWeight="bold">Installations: </Text>
                <Text ml={5}>{product.Installations}</Text>
              </Box>
            </VStack>
          </Box>
        </Box>
      </Center>
    </Box>
  );
};

export default ProductEach;
