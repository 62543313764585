import { Box, SimpleGrid, Img } from "@chakra-ui/react";
import React from "react";
import Images from "../components/Image";
import gallery from "../GalleryData";

const Gallery = () => {
  return (
    <Box display="flex" flexDirection="column" w="100%">
      <Box>
        <Images />
      </Box>
      <Box bgColor="#07274a" p={4}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
          {gallery.map((product) => (
            <Box
              key={product.id}
              borderRadius={5}
              overflow="hidden"
              position="relative"
              _hover={{ opacity: 0.8 }}
            >
              <Img
                src={product.image}
                alt={product.name}
                width="100%"
                height="200px"
                objectFit="cover"
                borderWidth={1}
                borderColor="gray"
              />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Gallery;
