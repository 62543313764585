import img1 from "./images/Gallery Images/cctv1.avif";
import img2 from "./images/Gallery Images/cctv2.jpg";
import img3 from "./images/Gallery Images/cctv3.jpg";
import img4 from "./images/Gallery Images/cctv4.jpg";
import img5 from "./images/Gallery Images/cctv5.jpg";
import img6 from "./images/Gallery Images/cctv6.jpg";
import img7 from "./images/Gallery Images/cctv7.jpg";
import img8 from "./images/Gallery Images/cctv8.jpg";
import img9 from "./images/Gallery Images/CCTV_camera_Delhi_government.jpeg";
import img10 from "./images/updatedImages/IMG-20240215-WA0018.jpg";
import img11 from "./images/updatedImages/IMG-20240215-WA0020.jpg";
import img12 from "./images/updatedImages/IMG20230721080139.jpg";
import img13 from "./images/updatedImages/IMG20230720192038.jpg";
import img14 from "./images/updatedImages/IMG20230721072540.jpg";
import img15 from "./images/updatedImages/IMG20230720192043.jpg";

const gallery = [
  {
    id: 1,
    image: img1,
  },
  {
    id: 2,
    image: img2,
  },
  {
    id: 3,
    image: img3,
  },
  {
    id: 4,
    image: img4,
  },
  {
    id: 5,
    image: img5,
  },
  {
    id: 6,
    image: img6,
  },
  {
    id: 7,
    image: img7,
  },
  {
    id: 8,
    image: img8,
  },
  {
    id: 9,
    image: img9,
  },
  {
    id: 10,
    image: img10,
  },
  {
    id: 11,
    image: img11,
  },
  {
    id: 12,
    image: img12,
  },
  {
    id: 13,
    image: img13,
  },
  {
    id: 14,
    image: img14,
  },
  {
    id: 15,
    image: img15,
  },
];

export default gallery;
