import { Box, Text } from "@chakra-ui/react";
import React from "react";

const CompanyDetails = () => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop={{ base: "30px", md: "60px" }}
        p={3}
        bgColor="#07274a"
      >
        <Box
          width={{ base: "90%", md: "700px" }}
          height="auto"
          borderWidth="4px"
          borderRadius="10px"
          marginTop={{ base: "60px", md: "0" }}
          display="flex"
          justifyContent="center"
          backgroundSize="cover"
        >
          <Box width="100%">
            <Box display="flex" justifyContent="center">
              <Text
                fontSize={{ base: "24px", md: "32px" }}
                fontWeight="bold"
                color="white"
                fontStyle="normal"
                paddingTop="40px"
              >
                Company Information
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="space-between"
              paddingLeft="10px"
              paddingRight="10px"
            >
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontWeight="bold"
                fontStyle="normal"
                paddingTop="20px"
              >
                Nature Of Business
              </Text>
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontStyle="normal"
                paddingTop="20px"
              >
                Installation and Services of CCTV Systems
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="space-between"
              paddingLeft="10px"
              paddingRight="10px"
            >
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontWeight="bold"
                fontStyle="normal"
                paddingTop="20px"
              >
                Company CEO
              </Text>
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontStyle="normal"
                paddingTop="20px"
              >
                Battula Kesava
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              paddingLeft="10px"
              paddingRight="10px"
              justifyContent="space-between"
            >
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontWeight="bold"
                fontStyle="normal"
                paddingTop="20px"
              >
                Official Address
              </Text>
              <Box display="flex" flexDirection="column">
                <Text
                  fontSize={{ base: "20px", md: "20px" }}
                  fontStyle="normal"
                  paddingTop="20px"
                >
                  Near NTR Park, Railway Station Road,
                </Text>
                <Text
                  fontSize={{ base: "20px", md: "20px" }}
                  fontStyle="normal"
                  paddingTop="20px"
                >
                  Sattenapalli, Guntur, Andhra Pradesh
                </Text>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              paddingRight="10px"
              paddingLeft="10px"
              justifyContent="space-between"
            >
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontWeight="bold"
                fontStyle="normal"
                paddingTop="20px"
              >
                Working Staff
              </Text>
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontStyle="normal"
                paddingTop="20px"
              >
                40 to 50 people
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              paddingRight="10px"
              paddingLeft="10px"
              justifyContent="space-between"
            >
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontWeight="bold"
                fontStyle="normal"
                paddingTop="20px"
              >
                Year of Establishment
              </Text>
              <Text
                fontSize={{ base: "20px", md: "20px" }}
                fontStyle="normal"
                paddingTop="20px"
              >
                2016
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDetails;
