import React from "react";
import products from "../data";
import { Box, Img, Text, chakra, SimpleGrid, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const HoverableText = chakra(Text, {
  baseStyle: {
    transition: "color 0.3s",
    _hover: { color: "red" },
  },
});

const ProductCard1 = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Text
          fontSize={{ base: "24px", md: "30px" }}
          fontWeight="bold"
          color="white"
        >
          Product Range
        </Text>
      </Box>
      <Center w={{ lg: "none", base: "100%", md: "100%" }}>
        <Box>
          <SimpleGrid
            columns={{ base: 1, md: 5 }}
            spacing={{ base: 4, md: 6 }}
            p={{ base: 2, md: 4 }}
          >
            {products
              .filter((product) => product.id >= 1 && product.id <= 5)
              .map((product) => (
                <Link key={product.id} to={`/products/${product.name}`}>
                  <Box bgColor="white" p={{ base: 2, md: 4 }} borderRadius={5}>
                    <Img
                      mt={4}
                      src={product.image}
                      alt={product.name}
                      width="100%"
                      height="200px"
                      objectFit="contain"
                      borderWidth={1}
                      borderColor="gray"
                    />
                    <HoverableText fontWeight="bold" mt={5} mb={5}>
                      {product.name}
                    </HoverableText>
                  </Box>
                </Link>
              ))}
          </SimpleGrid>
        </Box>
      </Center>
    </Box>
  );
};

export default ProductCard1;
