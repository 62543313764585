import React from "react";
import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import products from "../data";
import banner42 from "../images/banner Images/banner42.png";
import "../App.css"; // Import your CSS file

const Header = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      display="flex"
      flexDirection={{ lg: "row", base: "column", md: "row" }}
      alignItems="center"
      justifyContent="space-between"
      background="rgba(0,0,0,0.36)"
      h={{ base: "auto", md: "150px" }}
      w="100%"
      position="fixed"
      top="0"
      zIndex="100"
      px={{ base: "4", md: "8" }}
      flex="1"
    >
      <Box>
        <Image src={banner42} height="100px" width="300px" color="#cdc7c7" />
      </Box>
      <Box
        mt={{ base: "4", md: "0" }}
        display="flex"
        alignItems="center"
        justifyContent={{
          lg: "space-evenly",
          base: "space-between",
          md: "space-evenly",
        }}
        flexWrap="wrap"
        w={{ base: "100%" }}
      >
        <Button
          variant="link"
          color="white"
          className="btn"
          onClick={() => handleClick("/")}
        >
          Home
        </Button>
        <Button
          variant="link"
          color="white"
          className="btn"
          onClick={() => handleClick("/about")}
        >
          About
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            variant="link"
            color="white"
            className="btn"
            onClick={() => handleClick("/products")}
          >
            Products
          </MenuButton>
          <MenuList>
            {products.map((product) => (
              <MenuItem
                key={product.id}
                onClick={() => handleClick(`/products/${product.name}`)}
              >
                {product.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        <Button
          variant="link"
          color="white"
          className="btn"
          onClick={() => handleClick("/gallery")}
        >
          Gallery
        </Button>
        <Button
          variant="link"
          color="white"
          className="btn"
          onClick={() => handleClick("/contact")}
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
