// Loginform.js

import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

const Loginform = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');

  const toast = useToast();

  const handleSubmit = async () => {
    if (name && email && number) {
      try {
        const response = await axios.post(
          "http://localhost:7465/api/userInfo/send-email",
          {
            name: name,
            email: email,
            number: number,
          }
        );
        console.log(response);
        if (response.data.success) {
          toast({
            title: "Data Uploaded Successfully",
            description: "Your data has been uploaded successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setName("");
          setEmail("");
          setNumber("");
        }
      } catch (error) {
        toast({
          title: "Error Uploading Data",
          description: "There was an error uploading your data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error("Error uploading data:", error);
      }
    } else {
      toast({
        title: "Fill all the Details",
        description: "Please fill all the details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      flexDirection={{ base: "column", lg: "row" }}
      width={{ base: "100%", lg: "100%" }}
      mx="auto"
      borderWidth="1px"
      mt={{ base: "20px", lg: "30px" }}
      mb="20px"
      ml={{ base: "0px", lg: "auto" }}
      p="6"
      bg="white"
      color="black"
      borderRadius={15}
    >
      <FormControl mb="4" isRequired>
        <FormLabel>Full Name</FormLabel>
        <Input
          type="text"
          name="fullName"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          boxShadow="base"
        />
      </FormControl>

      <FormControl mb="4" isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          boxShadow="base"
        />
      </FormControl>

      <FormControl mb="4" isRequired>
        <FormLabel>Phone</FormLabel>
        <Input
          type="tel"
          name="phone"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          required
          boxShadow="base"
        />
      </FormControl>

      <Box textAlign="center" mt={{ base: "4", lg: "0" }}>
        <Button
          type="submit"
          size="lg"
          colorScheme="red"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default Loginform;
