import { Box, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import banner45 from "../images/banner Images/banner45.jpg";
import banner48 from "../images/banner Images/banner48.jpg";
import banner49 from "../images/banner Images/banner49.jpg";
import banner56 from "../images/banner Images/banner56.jpg";
import banner57 from "../images/banner Images/banner57.jpg";
import banner58 from "../images/banner Images/banner58.jpg";
import banner59 from "../images/banner Images/banner59.jpg";
import banner60 from "../images/banner Images/banner60.jpg";
import banner61 from "../images/banner Images/banner61.jpg";
import banner62 from "../images/banner Images/banner62.jpg";
import banner63 from "../images/banner Images/banner63.jpg";

const HomeSlider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    banner45,
    banner48,
    banner49,
    banner56,
    banner57,
    banner58,
    banner59,
    banner60,
    banner61,
    banner62,
    banner63,
  ];

  const intervalDuration = 3000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <Box
      display={{ base: "flex", lg: "flex" }}
      className="tracking-in-expand-fwd-top"
      w="100%"
      height="700px"
      overflow="hidden"
      position="relative"
    >
      {images.map((image, index) => (
        <Image
          key={index}
          className="images"
          src={image}
          alt={`banner-${index}`}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: index === currentImageIndex ? 1 : 0,
            transition: "opacity 0.9s ease-in-out",
          }}
        />
      ))}
    </Box>
  );
};

export default HomeSlider;
