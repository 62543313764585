import { Box, Text, Image, Center } from "@chakra-ui/react";
import React from "react";
import banner52 from "../images/banner Images/banner52.png";
import banner53 from "../images/banner Images/banner53.png";
import banner54 from "../images/banner Images/banner54.png";
import banner55 from "../images/banner Images/banner55.png";
import Loginform from "./LoginForm";

const Address = () => {
  return (
    <Box width="100%" p={{ base: "4", md: "8" }}>
      <Box
        mt={{ base: "170px", md: "150px" }}
        mb={{ base: "20px", md: "50px" }}
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-evenly"
      >
        <Box
          height={{ base: "200px", md: "300px" }}
          width={{ base: "100%", md: "300px" }}
          borderWidth="4px"
          borderRadius="10px"
          mb={{ base: "8", md: "0" }}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Image src={banner52} height="50px" width="50px" />
          <Box mt={{ base: "4", md: "20px" }}>
            <Text fontSize={{ lg: "19px", base: "18px", md: "22px" }}>
              <a href="mailto:info@kesavasecuritysystems.com">
                info@kesavasecuritysystems.com
              </a>
            </Text>
          </Box>
        </Box>
        <Box
          height={{ base: "200px", md: "300px" }}
          width={{ base: "100%", md: "300px" }}
          borderWidth="4px"
          borderRadius="10px"
          mb={{ base: "8", md: "0" }}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Image src={banner53} height="50px" width="50px" />
          <Box mt={{ base: "4", md: "20px" }}>
            <a style={{ fontSize: "24px" }} href="tel:+918886126474">
              +91 88861 26474
            </a>
            <br />
            <a style={{ fontSize: "24px" }} href="tel:+918639296905">
              +91 86392 96905
            </a>
          </Box>
        </Box>

        <Box
          height={{ base: "200px", md: "300px" }}
          width={{ base: "100%", md: "300px" }}
          borderWidth="4px"
          borderRadius="10px"
          mb={{ base: "8", md: "0" }}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Image src={banner54} height="50px" width="50px" />
          <Box mt={{ base: "4", md: "20px" }}>
            <Text fontSize={{ base: "18px", md: "22px" }}>
              Timings: 10:30 A.M-5:00 P.M
            </Text>
          </Box>
        </Box>

        <Box
          height={{ base: "200px", md: "300px" }}
          width={{ base: "100%", md: "300px" }}
          borderWidth="4px"
          borderRadius="10px"
          mb={{ base: "8", md: "0" }}
          pt={{ md: "60px" }}
          display="flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Image src={banner55} height="50px" width="50px" />
          <Box
            mt={{ base: "4", md: "20px" }}
            pl={{ md: "15px" }}
            textAlign="center"
          >
            <Text fontSize={{ base: "18px", md: "22px" }}>
              Near NTR Park, Railway Station Road, Sattenapalli, Guntur, Andhra
              Pradesh
            </Text>
          </Box>
        </Box>
      </Box>
      <Center>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={{ lg: "50%", base: "100%", md: "250px" }}
          height={{ base: "auto", md: "500px" }}
          ml={{ lg: "0", base: "0", md: "auto" }}
        >
          <Text
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight="bold"
            color="white"
          >
            Contact Us
          </Text>
          <Loginform />
        </Box>
      </Center>
    </Box>
  );
};

export default Address;
